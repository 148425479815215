var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"74f0a914e755a0f25ec1ed277ad3c76e81302e4a"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import sentryOptions from "src/core/analytics/sentryOptions";

Sentry.init({
  dsn: "https://1dd9fbcda276456dac2cbcde74340923@sentry.io/3578543",
  integrations: integrations =>
    integrations.filter(integration => integration.name !== "Replay"),
  ...sentryOptions,
  tracesSampleRate: 0.05,
});
