import AdyenConfig from "../adyen/config";
import LedgerGreenConfig from "../ledgerGreen/config";
import MerrcoConfig from "../merrco/config";
import MonerisConfig from "../moneris/config";
import SpenceConfig from "../spence/config";
import StrongholdConfig from "../stronghold/config";
import SwifterConfig from "../swifter/config";
import BlazepayConfig from "../blazepay/config";

const PaymentServices = [
  AdyenConfig,
  LedgerGreenConfig,
  MerrcoConfig,
  MonerisConfig,
  SpenceConfig,
  StrongholdConfig,
  SwifterConfig,
  BlazepayConfig,
].reduce((acc, config) => {
  return {
    ...acc,
    [config.type]: config,
  };
}, {});

export default PaymentServices;
