import TymberAPIConfig from "../api/config";
import {makeJsonAPIPayload, v1} from "../api/utils";

const Endpoints = {
  v1: {
    paymentOptionsBannersConfig: id =>
      v1(`store/payment-options/${id}/promotional-banners/config/`),

    token: type => v1(`store/payments/${type}/token/`),
    pay: (type, orderId) => v1(`store/payments/${type}/orders/${orderId}/pay/`),

    integrationConfiguration: type =>
      v1(`store/integrations/payments/${type}/configuration/`),
    configuration: type => v1(`store/payments/${type}/configuration/`),

    paymentSources: sourceType => v1(`store/payments/${sourceType}/sources/`),
    paymentSource: (type, id) => v1(`store/payments/${type}/sources/${id}/`),

    saveTip: type => v1(`store/payments/${type}/tip/`),

    promotions: type => v1(`store/payments/${type}/promotions/`),
    promotionSavings: type => v1(`store/payments/${type}/promotions/redeemable`),

    customers: type => v1(`store/payments/${type}/customers/`),

    externalAuthVerification: type => v1(`store/payments/${type}/sources/external-auth/`),

    session: type => v1(`store/payments/${type}/sessions/`),
  },
};

export function createPaymentSession(type, data) {
  const axios = TymberAPIConfig.axios();
  const payload = makeJsonAPIPayload(
    "payment_sources",
    {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone_number: data.phoneNumber,
    },
    {
      cart: {
        data: {
          type: "carts",
          id: data.cartId,
        },
      },
    }
  );
  return axios.post(Endpoints.v1.session(type), payload).then(response => response.data);
}

export function addPaymentSource(type, data) {
  const axios = TymberAPIConfig.axios();
  const payload = makeJsonAPIPayload("payment_sources", data);
  return axios
    .post(Endpoints.v1.paymentSources(type), payload)
    .then(response => response.data);
}

export function saveTip(type, tip) {
  const axios = TymberAPIConfig.axios();
  const payload = makeJsonAPIPayload("payment_sources", {
    external_id: tip.id,
    percentage: tip.percentage,
  });
  return axios.post(Endpoints.v1.saveTip(type), payload).then(response => response.data);
}

export function markAsDefaultPaymentSource(type, paymentSourceId) {
  const axios = TymberAPIConfig.axios();
  const payload = makeJsonAPIPayload(
    "payment_sources",
    {
      is_default: true,
    },
    undefined,
    paymentSourceId.toString()
  );
  return axios
    .patch(Endpoints.v1.paymentSource(type, paymentSourceId), payload)
    .then(response => response.data);
}

export function removePaymentSource(type, paymentSourceId) {
  const axios = TymberAPIConfig.axios();
  return axios
    .delete(Endpoints.v1.paymentSource(type, paymentSourceId))
    .then(response => response.data);
}

export function getToken(type) {
  const axios = TymberAPIConfig.axios();
  return axios.get(Endpoints.v1.token(type)).then(response => response.data);
}

export function pay(type, orderId, data) {
  const payload = makeJsonAPIPayload("payments_charge", data);

  const axios = TymberAPIConfig.axios();
  return axios
    .post(Endpoints.v1.pay(type, orderId), payload)
    .then(response => response.data);
}

export function getConfiguration(type) {
  const axios = TymberAPIConfig.axios();
  return axios.get(Endpoints.v1.configuration(type)).then(response => response.data);
}
export function getIntegrationConfiguration(type) {
  const axios = TymberAPIConfig.axios();
  return axios
    .get(Endpoints.v1.integrationConfiguration(type))
    .then(response => response.data);
}

export function createCustomer(type, cartId, attrs = {}) {
  const axios = TymberAPIConfig.axios();
  const payload = makeJsonAPIPayload(
    "payment_customers",
    attrs,
    {
      cart: {
        data: {
          type: "carts",
          id: cartId,
        },
      },
    },
    ""
  );

  return axios.put(Endpoints.v1.customers(type), payload).then(response => response.data);
}

export function getPromotions(type, {limit, offset}) {
  const axios = TymberAPIConfig.axios();
  return axios
    .get(Endpoints.v1.promotions(type), {params: {limit, offset}})
    .then(response => response.data);
}

export function getPromotionSavings(type, {chargeAmount}) {
  const axios = TymberAPIConfig.axios();
  const payload = makeJsonAPIPayload(
    "payment_redeemable_promotions",
    {
      charge_amount: chargeAmount,
    },
    undefined,
    ""
  );
  return axios
    .post(Endpoints.v1.promotionSavings(type), payload)
    .then(response => response.data);
}

export function paymentOptionsBannersConfig({id}) {
  const axios = TymberAPIConfig.axios();
  return axios
    .get(Endpoints.v1.paymentOptionsBannersConfig(id))
    .then(response => response.data);
}

export function paymentSources(sourceType) {
  const axios = TymberAPIConfig.axios();
  return axios
    .get(Endpoints.v1.paymentSources(sourceType))
    .then(response => response.data);
}

export function getExternalAuthVerification(type, {data, cartId}) {
  const axios = TymberAPIConfig.axios();
  const payload = makeJsonAPIPayload(
    "payment_external_auth",
    {
      ...data,
      challenge_screen_width: 600,
      challenge_screen_height: 400,
    },
    {
      cart: {
        data: {
          type: "carts",
          id: cartId,
        },
      },
    },
    ""
  );

  return axios
    .post(Endpoints.v1.externalAuthVerification(type), payload)
    .then(response => response.data);
}
