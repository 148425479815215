import OnlinePayment from "src/core/payments/OnlinePayment";
import * as PaymentsAPI from "src/core/api/payments";
import {PaymentOptions} from "src/core/payments/constants";
import {error as notifyError} from "src/core/notifications";

class BlazePay extends OnlinePayment {
  static scriptId = "blazepay-sdk";
  blazePayInstance = null;
  authorizationData = null;
  constructor() {
    super();
    if (window.BlazePay || document.getElementById(BlazePay.scriptId)) return;

    const script = document.createElement("script");
    script.id = BlazePay.scriptId;
    script.src =
      "https://blaze-sandbox-blazepay-web.s3.us-east-1.amazonaws.com/sdk/sandbox/latest/index.umd.js";

    document.body.appendChild(script);
  }

  async preparePayment(orderInfo, paymentData, cartId, isGuestCheckout) {
    if (!this.authorizationData) throw new Error("Payment not authorized");

    const {sessionId, paymentId} = this.authorizationData;
    return {
      ...orderInfo,
      payment_specification: {
        payment_session_id: sessionId,
        payment_session_payment_id: paymentId,
      },
    };
  }

  async authorizePayment(orderInfo, paymentData, cartId, isGuestCheckout) {
    this.authorizationData = null;
    const paymentSession = await this.createPaymentSession(orderInfo, cartId);

    return new Promise((resolve, reject) => {
      this.blazePayInstance = new window.BlazePay.Checkout({
        accessToken: paymentSession.accessToken,
        mode: window.location.href.includes("store-1") ? "production" : "test",
        controlledCapture: true,
        paymentTimeout: window.paymentTimeout || 20000,
        onPaymentAuthorized: result => {
          console.log("Result", result);
          this.authorizationData = result;
          resolve(result);
        },
        onError: error => {
          if (Array.isArray(error?.errors)) {
            notifyError(error.errors.join(". "));
          } else {
            notifyError("An error occurred while processing your payment");
          }
          reject(error);
        },
        onPaymentError: () => {
          console.log("An error occurred with your payment. Please try again.");
        },
        onClose: () => {
          reject("Checkout closed");
        },
      });

      this.blazePayInstance.initialize();
    });
  }

  async capturePayment() {
    if (!this.blazePayInstance || !this.authorizationData)
      throw new Error("Payment not authorized");

    return this.blazePayInstance.capturePayment();
  }

  async pay() {
    throw new Error("Not implemented");
  }

  async createPaymentSession(orderInfo, cartId) {
    const response = await PaymentsAPI.createPaymentSession(PaymentOptions.BLAZEPAY, {
      firstName: orderInfo.first_name,
      lastName: orderInfo.last_name,
      email: orderInfo.email,
      phoneNumber: orderInfo.phone_number,
      cartId,
    });

    return {
      accessToken: response.data.attributes.access_token,
    };
  }
}

export default BlazePay;
