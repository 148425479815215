export const ErrorMessages = {
  FAILED_TOKEN_GENERATION:
    "There's a configuration problem with the Payment Processor. Please get in touch with the Store to solve it.",
};

export const cardTypes = {
  visa: "visa",
  mastercard: "mastercard",
  master: "mastercard",
  amex: "amex",
  discover: "discover",
  jcb: "jcb",
  maestro: "maestro",
  hiper: "",
  hipercard: "",
  "american-express": "amex",
  "diners-club": "diners",
  diners: "diners",
  unionpay: "",
  mir: "",
};

export const PaymentOptions = {
  STRONGHOLD: "stronghold",
  BLAZEPAY: "blazepay_widget",
  SPENCE: "spence",
  MERRCO: "merrco",
  MONERIS: "moneris",
  ADYEN: "adyen",
  LEDGERGREEN: "ledgergreen",
  SWIFTER: "swifter",
};

export const UkRegions = [
  {
    abbreviation: "gb-abe",
    name: "Aberdeen City",
  },
  {
    abbreviation: "gb-abd",
    name: "Aberdeenshire",
  },
  {
    abbreviation: "gb-ans",
    name: "Angus",
  },
  {
    abbreviation: "gb-ann",
    name: "Antrim and Newtownabbey",
  },
  {
    abbreviation: "gb-and",
    name: "Ards and North Down",
  },
  {
    abbreviation: "gb-agb",
    name: "Argyll and Bute",
  },
  {
    abbreviation: "gb-abc",
    name: "Armagh City, Banbridge and Craigavon",
  },
  {
    abbreviation: "gb-bdg",
    name: "Barking and Dagenham",
  },
  {
    abbreviation: "gb-bne",
    name: "Barnet",
  },
  {
    abbreviation: "gb-bns",
    name: "Barnsley",
  },
  {
    abbreviation: "gb-bas",
    name: "Bath and North East Somerset",
  },
  {
    abbreviation: "gb-bdf",
    name: "Bedford",
  },
  {
    abbreviation: "gb-bfs",
    name: "Belfast City",
  },
  {
    abbreviation: "gb-bex",
    name: "Bexley",
  },
  {
    abbreviation: "gb-bir",
    name: "Birmingham",
  },
  {
    abbreviation: "gb-bbd",
    name: "Blackburn with Darwen",
  },
  {
    abbreviation: "gb-bpl",
    name: "Blackpool",
  },
  {
    abbreviation: "gb-bgw",
    name: "Blaenau Gwent",
  },
  {
    abbreviation: "gb-bol",
    name: "Bolton",
  },
  {
    abbreviation: "gb-bcp*",
    name: "Bournemouth, Christchurch and Poole",
  },
  {
    abbreviation: "gb-brc",
    name: "Bracknell Forest",
  },
  {
    abbreviation: "gb-brd",
    name: "Bradford",
  },
  {
    abbreviation: "gb-ben",
    name: "Brent",
  },
  {
    abbreviation: "gb-bge",
    name: "Bridgend [Pen-y-bont ar Ogwr GB-POG]",
  },
  {
    abbreviation: "gb-bnh",
    name: "Brighton and Hove",
  },
  {
    abbreviation: "gb-bst",
    name: "Bristol, City of",
  },
  {
    abbreviation: "gb-bry",
    name: "Bromley",
  },
  {
    abbreviation: "gb-bkm",
    name: "Buckinghamshire",
  },
  {
    abbreviation: "gb-bur",
    name: "Bury",
  },
  {
    abbreviation: "gb-cay",
    name: "Caerphilly [Caerffili GB-CAF]",
  },
  {
    abbreviation: "gb-cld",
    name: "Calderdale",
  },
  {
    abbreviation: "gb-cam",
    name: "Cambridgeshire",
  },
  {
    abbreviation: "gb-cmd",
    name: "Camden",
  },
  {
    abbreviation: "gb-crf",
    name: "Cardiff [Caerdydd GB-CRD]",
  },
  {
    abbreviation: "gb-cmn",
    name: "Carmarthenshire [Sir Gaerfyrddin GB-GFY]",
  },
  {
    abbreviation: "gb-ccg",
    name: "Causeway Coast and Glens",
  },
  {
    abbreviation: "gb-cbf*",
    name: "Central Bedfordshire",
  },
  {
    abbreviation: "gb-cgn",
    name: "Ceredigion [Sir Ceredigion]",
  },
  {
    abbreviation: "gb-che*",
    name: "Cheshire East",
  },
  {
    abbreviation: "gb-chw*",
    name: "Cheshire West and Chester",
  },
  {
    abbreviation: "gb-clk",
    name: "Clackmannanshire",
  },
  {
    abbreviation: "gb-cwy",
    name: "Conwy",
  },
  {
    abbreviation: "gb-con",
    name: "Cornwall",
  },
  {
    abbreviation: "gb-cov",
    name: "Coventry",
  },
  {
    abbreviation: "gb-cry",
    name: "Croydon",
  },
  {
    abbreviation: "gb-cma",
    name: "Cumbria",
  },
  {
    abbreviation: "gb-dal",
    name: "Darlington",
  },
  {
    abbreviation: "gb-den",
    name: "Denbighshire [Sir Ddinbych GB-DDB]",
  },
  {
    abbreviation: "gb-der",
    name: "Derby",
  },
  {
    abbreviation: "gb-dby",
    name: "Derbyshire",
  },
  {
    abbreviation: "gb-drs",
    name: "Derry and Strabane",
  },
  {
    abbreviation: "gb-dev",
    name: "Devon",
  },
  {
    abbreviation: "gb-dnc",
    name: "Doncaster",
  },
  {
    abbreviation: "gb-dor",
    name: "Dorset",
  },
  {
    abbreviation: "gb-dud",
    name: "Dudley",
  },
  {
    abbreviation: "gb-dgy",
    name: "Dumfries and Galloway",
  },
  {
    abbreviation: "gb-dnd",
    name: "Dundee City",
  },
  {
    abbreviation: "gb-dur",
    name: "Durham, County",
  },
  {
    abbreviation: "gb-eal",
    name: "Ealing",
  },
  {
    abbreviation: "gb-eay",
    name: "East Ayrshire",
  },
  {
    abbreviation: "gb-edu",
    name: "East Dunbartonshire",
  },
  {
    abbreviation: "gb-eln",
    name: "East Lothian",
  },
  {
    abbreviation: "gb-erw",
    name: "East Renfrewshire",
  },
  {
    abbreviation: "gb-ery",
    name: "East Riding of Yorkshire",
  },
  {
    abbreviation: "gb-esx",
    name: "East Sussex",
  },
  {
    abbreviation: "gb-edh",
    name: "Edinburgh, City of",
  },
  {
    abbreviation: "gb-els",
    name: "Eilean Siar",
  },
  {
    abbreviation: "gb-enf",
    name: "Enfield",
  },
  {
    abbreviation: "gb-eng",
    name: "England",
  },
  {
    abbreviation: "gb-ess",
    name: "Essex",
  },
  {
    abbreviation: "gb-fal",
    name: "Falkirk",
  },
  {
    abbreviation: "gb-fmo",
    name: "Fermanagh and Omagh",
  },
  {
    abbreviation: "gb-fif",
    name: "Fife",
  },
  {
    abbreviation: "gb-fln",
    name: "Flintshire [Sir y Fflint GB-FFL]",
  },
  {
    abbreviation: "gb-gat",
    name: "Gateshead",
  },
  {
    abbreviation: "gb-glg",
    name: "Glasgow City",
  },
  {
    abbreviation: "gb-gls",
    name: "Gloucestershire",
  },
  {
    abbreviation: "gb-gre",
    name: "Greenwich",
  },
  {
    abbreviation: "gb-gwn",
    name: "Gwynedd",
  },
  {
    abbreviation: "gb-hck",
    name: "Hackney",
  },
  {
    abbreviation: "gb-hal",
    name: "Halton",
  },
  {
    abbreviation: "gb-hmf",
    name: "Hammersmith and Fulham",
  },
  {
    abbreviation: "gb-ham",
    name: "Hampshire",
  },
  {
    abbreviation: "gb-hry",
    name: "Haringey",
  },
  {
    abbreviation: "gb-hrw",
    name: "Harrow",
  },
  {
    abbreviation: "gb-hpl",
    name: "Hartlepool",
  },
  {
    abbreviation: "gb-hav",
    name: "Havering",
  },
  {
    abbreviation: "gb-hef",
    name: "Herefordshire",
  },
  {
    abbreviation: "gb-hrt",
    name: "Hertfordshire",
  },
  {
    abbreviation: "gb-hld",
    name: "Highland",
  },
  {
    abbreviation: "gb-hil",
    name: "Hillingdon",
  },
  {
    abbreviation: "gb-hns",
    name: "Hounslow",
  },
  {
    abbreviation: "gb-ivc",
    name: "Inverclyde",
  },
  {
    abbreviation: "gb-agy",
    name: "Isle of Anglesey [Sir Ynys Môn GB-YNM]",
  },
  {
    abbreviation: "gb-iow",
    name: "Isle of Wight",
  },
  {
    abbreviation: "gb-ios*",
    name: "Isles of Scilly",
  },
  {
    abbreviation: "gb-isl",
    name: "Islington",
  },
  {
    abbreviation: "gb-kec",
    name: "Kensington and Chelsea",
  },
  {
    abbreviation: "gb-ken",
    name: "Kent",
  },
  {
    abbreviation: "gb-khl",
    name: "Kingston upon Hull",
  },
  {
    abbreviation: "gb-ktt",
    name: "Kingston upon Thames",
  },
  {
    abbreviation: "gb-kir",
    name: "Kirklees",
  },
  {
    abbreviation: "gb-kwl",
    name: "Knowsley",
  },
  {
    abbreviation: "gb-lbh",
    name: "Lambeth",
  },
  {
    abbreviation: "gb-lan",
    name: "Lancashire",
  },
  {
    abbreviation: "gb-lds",
    name: "Leeds",
  },
  {
    abbreviation: "gb-lce",
    name: "Leicester",
  },
  {
    abbreviation: "gb-lec",
    name: "Leicestershire",
  },
  {
    abbreviation: "gb-lew",
    name: "Lewisham",
  },
  {
    abbreviation: "gb-lin",
    name: "Lincolnshire",
  },
  {
    abbreviation: "gb-lbc",
    name: "Lisburn and Castlereagh",
  },
  {
    abbreviation: "gb-liv",
    name: "Liverpool",
  },
  {
    abbreviation: "gb-lnd",
    name: "London, City of",
  },
  {
    abbreviation: "gb-lut",
    name: "Luton",
  },
  {
    abbreviation: "gb-man",
    name: "Manchester",
  },
  {
    abbreviation: "gb-mdw",
    name: "Medway",
  },
  {
    abbreviation: "gb-mty",
    name: "Merthyr Tydfil [Merthyr Tudful GB-MTU]",
  },
  {
    abbreviation: "gb-mrt",
    name: "Merton",
  },
  {
    abbreviation: "gb-mea",
    name: "Mid and East Antrim",
  },
  {
    abbreviation: "gb-mul",
    name: "Mid-Ulster",
  },
  {
    abbreviation: "gb-mdb",
    name: "Middlesbrough",
  },
  {
    abbreviation: "gb-mln",
    name: "Midlothian",
  },
  {
    abbreviation: "gb-mik",
    name: "Milton Keynes",
  },
  {
    abbreviation: "gb-mon",
    name: "Monmouthshire [Sir Fynwy GB-FYN]",
  },
  {
    abbreviation: "gb-mry",
    name: "Moray",
  },
  {
    abbreviation: "gb-ntl",
    name: "Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]",
  },
  {
    abbreviation: "gb-net",
    name: "Newcastle upon Tyne",
  },
  {
    abbreviation: "gb-nwm",
    name: "Newham",
  },
  {
    abbreviation: "gb-nwp",
    name: "Newport [Casnewydd GB-CNW]",
  },
  {
    abbreviation: "gb-nmd",
    name: "Newry, Mourne and Down",
  },
  {
    abbreviation: "gb-nfk",
    name: "Norfolk",
  },
  {
    abbreviation: "gb-nay",
    name: "North Ayrshire",
  },
  {
    abbreviation: "gb-nel",
    name: "North East Lincolnshire",
  },
  {
    abbreviation: "gb-nlk",
    name: "North Lanarkshire",
  },
  {
    abbreviation: "gb-nln",
    name: "North Lincolnshire",
  },
  {
    abbreviation: "gb-nnh*",
    name: "North Northamptonshire",
  },
  {
    abbreviation: "gb-nsm",
    name: "North Somerset",
  },
  {
    abbreviation: "gb-nty",
    name: "North Tyneside",
  },
  {
    abbreviation: "gb-nyk",
    name: "North Yorkshire",
  },
  {
    abbreviation: "gb-nir",
    name: "Northern Ireland",
  },
  {
    abbreviation: "gb-nbl",
    name: "Northumberland",
  },
  {
    abbreviation: "gb-ngm",
    name: "Nottingham",
  },
  {
    abbreviation: "gb-ntt",
    name: "Nottinghamshire",
  },
  {
    abbreviation: "gb-old",
    name: "Oldham",
  },
  {
    abbreviation: "gb-ork",
    name: "Orkney Islands",
  },
  {
    abbreviation: "gb-oxf",
    name: "Oxfordshire",
  },
  {
    abbreviation: "gb-pem",
    name: "Pembrokeshire [Sir Benfro GB-BNF]",
  },
  {
    abbreviation: "gb-pkn",
    name: "Perth and Kinross",
  },
  {
    abbreviation: "gb-pte",
    name: "Peterborough",
  },
  {
    abbreviation: "gb-ply",
    name: "Plymouth",
  },
  {
    abbreviation: "gb-por",
    name: "Portsmouth",
  },
  {
    abbreviation: "gb-pow",
    name: "Powys",
  },
  {
    abbreviation: "gb-rdg",
    name: "Reading",
  },
  {
    abbreviation: "gb-rdb",
    name: "Redbridge",
  },
  {
    abbreviation: "gb-rcc",
    name: "Redcar and Cleveland",
  },
  {
    abbreviation: "gb-rfw",
    name: "Renfrewshire",
  },
  {
    abbreviation: "gb-rct",
    name: "Rhondda Cynon Taff [Rhondda CynonTaf]",
  },
  {
    abbreviation: "gb-ric",
    name: "Richmond upon Thames",
  },
  {
    abbreviation: "gb-rch",
    name: "Rochdale",
  },
  {
    abbreviation: "gb-rot",
    name: "Rotherham",
  },
  {
    abbreviation: "gb-rut",
    name: "Rutland",
  },
  {
    abbreviation: "gb-slf",
    name: "Salford",
  },
  {
    abbreviation: "gb-saw",
    name: "Sandwell",
  },
  {
    abbreviation: "gb-sct",
    name: "Scotland",
  },
  {
    abbreviation: "gb-scb",
    name: "Scottish Borders",
  },
  {
    abbreviation: "gb-sft",
    name: "Sefton",
  },
  {
    abbreviation: "gb-shf",
    name: "Sheffield",
  },
  {
    abbreviation: "gb-zet",
    name: "Shetland Islands",
  },
  {
    abbreviation: "gb-shr",
    name: "Shropshire",
  },
  {
    abbreviation: "gb-slg",
    name: "Slough",
  },
  {
    abbreviation: "gb-sol",
    name: "Solihull",
  },
  {
    abbreviation: "gb-som",
    name: "Somerset",
  },
  {
    abbreviation: "gb-say",
    name: "South Ayrshire",
  },
  {
    abbreviation: "gb-sgc",
    name: "South Gloucestershire",
  },
  {
    abbreviation: "gb-slk",
    name: "South Lanarkshire",
  },
  {
    abbreviation: "gb-sty",
    name: "South Tyneside",
  },
  {
    abbreviation: "gb-sth",
    name: "Southampton",
  },
  {
    abbreviation: "gb-sos",
    name: "Southend-on-Sea",
  },
  {
    abbreviation: "gb-swk",
    name: "Southwark",
  },
  {
    abbreviation: "gb-shn",
    name: "St. Helens",
  },
  {
    abbreviation: "gb-sts",
    name: "Staffordshire",
  },
  {
    abbreviation: "gb-stg",
    name: "Stirling",
  },
  {
    abbreviation: "gb-skp",
    name: "Stockport",
  },
  {
    abbreviation: "gb-stt",
    name: "Stockton-on-Tees",
  },
  {
    abbreviation: "gb-ste",
    name: "Stoke-on-Trent",
  },
  {
    abbreviation: "gb-sfk",
    name: "Suffolk",
  },
  {
    abbreviation: "gb-snd",
    name: "Sunderland",
  },
  {
    abbreviation: "gb-sry",
    name: "Surrey",
  },
  {
    abbreviation: "gb-stn",
    name: "Sutton",
  },
  {
    abbreviation: "gb-swa",
    name: "Swansea [Abertawe GB-ATA]",
  },
  {
    abbreviation: "gb-swd",
    name: "Swindon",
  },
  {
    abbreviation: "gb-tam",
    name: "Tameside",
  },
  {
    abbreviation: "gb-tfw",
    name: "Telford and Wrekin",
  },
  {
    abbreviation: "gb-thr",
    name: "Thurrock",
  },
  {
    abbreviation: "gb-tob",
    name: "Torbay",
  },
  {
    abbreviation: "gb-tof",
    name: "Torfaen [Tor-faen]",
  },
  {
    abbreviation: "gb-twh",
    name: "Tower Hamlets",
  },
  {
    abbreviation: "gb-trf",
    name: "Trafford",
  },
  {
    abbreviation: "gb-vgl",
    name: "Vale of Glamorgan, The [Bro Morgannwg GB-BMG]",
  },
  {
    abbreviation: "gb-wkf",
    name: "Wakefield",
  },
  {
    abbreviation: "gb-wls",
    name: "Wales [Cymru GB-CYM]",
  },
  {
    abbreviation: "gb-wll",
    name: "Walsall",
  },
  {
    abbreviation: "gb-wft",
    name: "Waltham Forest",
  },
  {
    abbreviation: "gb-wnd",
    name: "Wandsworth",
  },
  {
    abbreviation: "gb-wrt",
    name: "Warrington",
  },
  {
    abbreviation: "gb-war",
    name: "Warwickshire",
  },
  {
    abbreviation: "gb-wbk",
    name: "West Berkshire",
  },
  {
    abbreviation: "gb-wdu",
    name: "West Dunbartonshire",
  },
  {
    abbreviation: "gb-wln",
    name: "West Lothian",
  },
  {
    abbreviation: "gb-wnh*",
    name: "West Northamptonshire",
  },
  {
    abbreviation: "gb-wsx",
    name: "West Sussex",
  },
  {
    abbreviation: "gb-wsm",
    name: "Westminster",
  },
  {
    abbreviation: "gb-wgn",
    name: "Wigan",
  },
  {
    abbreviation: "gb-wil",
    name: "Wiltshire",
  },
  {
    abbreviation: "gb-wnm",
    name: "Windsor and Maidenhead",
  },
  {
    abbreviation: "gb-wrl",
    name: "Wirral",
  },
  {
    abbreviation: "gb-wok",
    name: "Wokingham",
  },
  {
    abbreviation: "gb-wlv",
    name: "Wolverhampton",
  },
  {
    abbreviation: "gb-wor",
    name: "Worcestershire",
  },
  {
    abbreviation: "gb-wrx",
    name: "Wrexham [Wrecsam GB-WRC]",
  },
  {
    abbreviation: "gb-yor",
    name: "York",
  },
];
export const Countries = [
  {value: "af", label: "Afghanistan"},
  {value: "ax", label: "Aland Islands"},
  {value: "al", label: "Albania"},
  {value: "dz", label: "Algeria"},
  {value: "as", label: "American Samoa"},
  {value: "ad", label: "Andorra"},
  {value: "ao", label: "Angola"},
  {value: "ai", label: "Anguilla"},
  {value: "aq", label: "Antarctica"},
  {value: "ag", label: "Antigua And Barbuda"},
  {value: "ar", label: "Argentina"},
  {value: "am", label: "Armenia"},
  {value: "aw", label: "Aruba"},
  {value: "au", label: "Australia"},
  {value: "at", label: "Austria"},
  {value: "az", label: "Azerbaijan"},
  {value: "bs", label: "Bahamas"},
  {value: "bh", label: "Bahrain"},
  {value: "bd", label: "Bangladesh"},
  {value: "bb", label: "Barbados"},
  {value: "by", label: "Belarus"},
  {value: "be", label: "Belgium"},
  {value: "bz", label: "Belize"},
  {value: "bj", label: "Benin"},
  {value: "bm", label: "Bermuda"},
  {value: "bt", label: "Bhutan"},
  {value: "bo", label: "Bolivia"},
  {value: "ba", label: "Bosnia And Herzegovina"},
  {value: "bw", label: "Botswana"},
  {value: "bv", label: "Bouvet Island"},
  {value: "br", label: "Brazil"},
  {value: "io", label: "British Indian Ocean Territory"},
  {value: "bn", label: "Brunei Darussalam"},
  {value: "bg", label: "Bulgaria"},
  {value: "bf", label: "Burkina Faso"},
  {value: "bi", label: "Burundi"},
  {value: "kh", label: "Cambodia"},
  {value: "cm", label: "Cameroon"},
  {value: "ca", label: "Canada"},
  {value: "cv", label: "Cape Verde"},
  {value: "ky", label: "Cayman Islands"},
  {value: "cf", label: "Central African Republic"},
  {value: "td", label: "Chad"},
  {value: "cl", label: "Chile"},
  {value: "cn", label: "China"},
  {value: "cx", label: "Christmas Island"},
  {value: "cc", label: "Cocos (Keeling) Islands"},
  {value: "co", label: "Colombia"},
  {value: "km", label: "Comoros"},
  {value: "cg", label: "Congo"},
  {value: "cd", label: "Congo}, Democratic Republic"},
  {value: "ck", label: "Cook Islands"},
  {value: "cr", label: "Costa Rica"},
  {value: "ci", label: "Cote D'Ivoire"},
  {value: "hr", label: "Croatia"},
  {value: "cu", label: "Cuba"},
  {value: "cy", label: "Cyprus"},
  {value: "cz", label: "Czech Republic"},
  {value: "dk", label: "Denmark"},
  {value: "dj", label: "Djibouti"},
  {value: "dm", label: "Dominica"},
  {value: "do", label: "Dominican Republic"},
  {value: "ec", label: "Ecuador"},
  {value: "eg", label: "Egypt"},
  {value: "sv", label: "El Salvador"},
  {value: "gq", label: "Equatorial Guinea"},
  {value: "er", label: "Eritrea"},
  {value: "ee", label: "Estonia"},
  {value: "et", label: "Ethiopia"},
  {value: "fk", label: "Falkland Islands (Malvinas)"},
  {value: "fo", label: "Faroe Islands"},
  {value: "fj", label: "Fiji"},
  {value: "fi", label: "Finland"},
  {value: "fr", label: "France"},
  {value: "gf", label: "French Guiana"},
  {value: "pf", label: "French Polynesia"},
  {value: "tf", label: "French Southern Territories"},
  {value: "ga", label: "Gabon"},
  {value: "gm", label: "Gambia"},
  {value: "ge", label: "Georgia"},
  {value: "de", label: "Germany"},
  {value: "gh", label: "Ghana"},
  {value: "gi", label: "Gibraltar"},
  {value: "gr", label: "Greece"},
  {value: "gl", label: "Greenland"},
  {value: "gd", label: "Grenada"},
  {value: "gp", label: "Guadeloupe"},
  {value: "gu", label: "Guam"},
  {value: "gt", label: "Guatemala"},
  {value: "gg", label: "Guernsey"},
  {value: "gn", label: "Guinea"},
  {value: "gw", label: "Guinea-Bissau"},
  {value: "gy", label: "Guyana"},
  {value: "ht", label: "Haiti"},
  {value: "hm", label: "Heard Island & Mcdonald Islands"},
  {value: "va", label: "Holy See (Vatican City State)"},
  {value: "hn", label: "Honduras"},
  {value: "hk", label: "Hong Kong"},
  {value: "hu", label: "Hungary"},
  {value: "is", label: "Iceland"},
  {value: "in", label: "India"},
  {value: "id", label: "Indonesia"},
  {value: "ir", label: "Iran}, Islamic Republic Of"},
  {value: "iq", label: "Iraq"},
  {value: "ie", label: "Ireland"},
  {value: "im", label: "Isle Of Man"},
  {value: "il", label: "Israel"},
  {value: "it", label: "Italy"},
  {value: "jm", label: "Jamaica"},
  {value: "jp", label: "Japan"},
  {value: "je", label: "Jersey"},
  {value: "jo", label: "Jordan"},
  {value: "kz", label: "Kazakhstan"},
  {value: "ke", label: "Kenya"},
  {value: "ki", label: "Kiribati"},
  {value: "kr", label: "Korea"},
  {value: "kw", label: "Kuwait"},
  {value: "kg", label: "Kyrgyzstan"},
  {value: "la", label: "Lao People's Democratic Republic"},
  {value: "lv", label: "Latvia"},
  {value: "lb", label: "Lebanon"},
  {value: "ls", label: "Lesotho"},
  {value: "lr", label: "Liberia"},
  {value: "ly", label: "Libyan Arab Jamahiriya"},
  {value: "li", label: "Liechtenstein"},
  {value: "lt", label: "Lithuania"},
  {value: "lu", label: "Luxembourg"},
  {value: "mo", label: "Macao"},
  {value: "mk", label: "Macedonia"},
  {value: "mg", label: "Madagascar"},
  {value: "mw", label: "Malawi"},
  {value: "my", label: "Malaysia"},
  {value: "mv", label: "Maldives"},
  {value: "ml", label: "Mali"},
  {value: "mt", label: "Malta"},
  {value: "mh", label: "Marshall Islands"},
  {value: "mq", label: "Martinique"},
  {value: "mr", label: "Mauritania"},
  {value: "mu", label: "Mauritius"},
  {value: "yt", label: "Mayotte"},
  {value: "mx", label: "Mexico"},
  {value: "fm", label: "Micronesia}, Federated States Of"},
  {value: "md", label: "Moldova"},
  {value: "mc", label: "Monaco"},
  {value: "MN", label: "Mongolia"},
  {value: "ME", label: "Montenegro"},
  {value: "MS", label: "Montserrat"},
  {value: "MA", label: "Morocco"},
  {value: "mz", label: "Mozambique"},
  {value: "mm", label: "Myanmar"},
  {value: "na", label: "Namibia"},
  {value: "nr", label: "Nauru"},
  {value: "np", label: "Nepal"},
  {value: "nl", label: "Netherlands"},
  {value: "an", label: "Netherlands Antilles"},
  {value: "nc", label: "New Caledonia"},
  {value: "nz", label: "New Zealand"},
  {value: "ni", label: "Nicaragua"},
  {value: "ne", label: "Niger"},
  {value: "ng", label: "Nigeria"},
  {value: "nu", label: "Niue"},
  {value: "nf", label: "Norfolk Island"},
  {value: "mp", label: "Northern Mariana Islands"},
  {value: "no", label: "Norway"},
  {value: "om", label: "Oman"},
  {value: "pk", label: "Pakistan"},
  {value: "pw", label: "Palau"},
  {value: "ps", label: "Palestinian Territory}, Occupied"},
  {value: "pa", label: "Panama"},
  {value: "pg", label: "Papua New Guinea"},
  {value: "py", label: "Paraguay"},
  {value: "pe", label: "Peru"},
  {value: "ph", label: "Philippines"},
  {value: "pn", label: "Pitcairn"},
  {value: "pl", label: "Poland"},
  {value: "pt", label: "Portugal"},
  {value: "pr", label: "Puerto Rico"},
  {value: "qa", label: "Qatar"},
  {value: "re", label: "Reunion"},
  {value: "ro", label: "Romania"},
  {value: "ru", label: "Russian Federation"},
  {value: "rw", label: "Rwanda"},
  {value: "bl", label: "Saint Barthelemy"},
  {value: "sh", label: "Saint Helena"},
  {value: "kn", label: "Saint Kitts And Nevis"},
  {value: "lc", label: "Saint Lucia"},
  {value: "mf", label: "Saint Martin"},
  {value: "pm", label: "Saint Pierre And Miquelon"},
  {value: "vc", label: "Saint Vincent And Grenadines"},
  {value: "ws", label: "Samoa"},
  {value: "sm", label: "San Marino"},
  {value: "st", label: "Sao Tome And Principe"},
  {value: "sa", label: "Saudi Arabia"},
  {value: "sn", label: "Senegal"},
  {value: "rs", label: "Serbia"},
  {value: "sc", label: "Seychelles"},
  {value: "sl", label: "Sierra Leone"},
  {value: "sg", label: "Singapore"},
  {value: "sk", label: "Slovakia"},
  {value: "si", label: "Slovenia"},
  {value: "sb", label: "Solomon Islands"},
  {value: "so", label: "Somalia"},
  {value: "za", label: "South Africa"},
  {value: "gs", label: "South Georgia And Sandwich Isl."},
  {value: "es", label: "Spain"},
  {value: "lk", label: "Sri Lanka"},
  {value: "sd", label: "Sudan"},
  {value: "sr", label: "Suriname"},
  {value: "sj", label: "Svalbard And Jan Mayen"},
  {value: "sz", label: "Swaziland"},
  {value: "se", label: "Sweden"},
  {value: "ch", label: "Switzerland"},
  {value: "sy", label: "Syrian Arab Republic"},
  {value: "tw", label: "Taiwan"},
  {value: "tj", label: "Tajikistan"},
  {value: "tz", label: "Tanzania"},
  {value: "th", label: "Thailand"},
  {value: "tl", label: "Timor-Leste"},
  {value: "tg", label: "Togo"},
  {value: "tk", label: "Tokelau"},
  {value: "to", label: "Tonga"},
  {value: "tt", label: "Trinidad And Tobago"},
  {value: "tn", label: "Tunisia"},
  {value: "tr", label: "Turkey"},
  {value: "tm", label: "Turkmenistan"},
  {value: "tc", label: "Turks And Caicos Islands"},
  {value: "tv", label: "Tuvalu"},
  {value: "ug", label: "Uganda"},
  {value: "ua", label: "Ukraine"},
  {value: "ae", label: "United Arab Emirates"},
  {value: "gb", label: "United Kingdom"},
  {value: "us", label: "United States"},
  {value: "um", label: "United States Outlying Islands"},
  {value: "uy", label: "Uruguay"},
  {value: "uz", label: "Uzbekistan"},
  {value: "vu", label: "Vanuatu"},
  {value: "ve", label: "Venezuela"},
  {value: "vn", label: "Vietnam"},
  {value: "vg", label: "Virgin Islands}, British"},
  {value: "vi", label: "Virgin Islands}, U.S."},
  {value: "wf", label: "Wallis And Futuna"},
  {value: "eh", label: "Western Sahara"},
  {value: "ye", label: "Yemen"},
  {value: "zm", label: "Zambia"},
  {value: "zw", label: "Zimbabwe"},
];
