import {PaymentOptions} from "src/core/payments/constants";
import {CreditCardPaymentSource} from "src/core/payments/models/paymentSource";
import BlazePay from "src/core/payments/blazepay/BlazePay";

export default {
  type: PaymentOptions.BLAZEPAY,
  PaymentClass: BlazePay,
  PaymentForm: null,
  PaymentSource: {
    class: CreditCardPaymentSource,
  },
};
